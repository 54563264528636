.project-setup {
  .cmcty-table-thead>tr>th {
    font-weight: normal;
  }
  thead > tr > th, thead > tr > th * {
    font-size: 12px !important;
    font-weight: bold !important;
  }

  tr.light-green,
  .cmcty-table-tbody tr.cmcty-table-row-selected.light-green {
    background-color: rgb(238, 252, 238);

    > td.cmcty-table-cell-fix-left,
    > td.cmcty-table-cell-fix-right,
    > td.cmcty-table-cell-row-hover,
    > td.cmcty-table-cell,
    > td.cmcty-table-column-sort {
      background-color: rgb(238, 252, 238);
    }
  }

  tr.light-red,
  .cmcty-table-tbody tr.cmcty-table-row-selected.light-red {
    background-color: rgb(255, 228, 228);

    > td.cmcty-table-cell-fix-left,
    > td.cmcty-table-cell-fix-right,
    > td.cmcty-table-cell-row-hover,
    > td.cmcty-table-cell,
    > td.cmcty-table-column-sort {
      background-color: rgb(255, 228, 228);
    }
  }

  tr.light-gray,
  .cmcty-table-tbody tr.cmcty-table-row-selected.light-gray {
    background-color: rgb(230, 230, 230);

    > td.cmcty-table-cell-fix-left,
    > td.cmcty-table-cell-fix-right,
    > td.cmcty-table-cell-row-hover,
    > td.cmcty-table-cell,
    > td.cmcty-table-column-sort {
      background-color: rgb(230, 230, 230);
    }
  }

  tr.light-dark-gray,
  .cmcty-table-tbody tr.cmcty-table-row-selected.light-dark-gray {
    background-color: rgb(245, 245, 245);

    > td.cmcty-table-cell-fix-left,
    > td.cmcty-table-cell-fix-right,
    > td.cmcty-table-cell-row-hover,
    > td.cmcty-table-cell,
    > td.cmcty-table-column-sort {
      background-color: rgb(245, 245, 245);
    }
  }

  tr.light-selected,
  .cmcty-table-tbody tr.cmcty-table-row-selected.light-selected {
    background-color: rgb(237, 246, 255);

    > td.cmcty-table-cell-fix-left,
    > td.cmcty-table-cell-fix-right,
    > td.cmcty-table-cell-row-hover,
    > td.cmcty-table-cell,
    > td.cmcty-table-column-sort {
      background-color: rgb(255, 255, 255);
    }
  }

  .operation-container {
    margin-bottom: 6px;
    padding: 10px;
    background-color: white;
  }

  .cmcty-tabs-content.cmcty-tabs-content-top {
    height: 100%;
  }

  .cmcty-row.cmcty-form-item {
    margin-bottom: 10px;
  }

  .project-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 32px;
    padding-right: 0;
    padding-left: 0;
  }

  .cmcty-input-number {
    width: 100%;
  }

  .project-select {
    width: 100%;

    &.left {
      .cmcty-select-selector {
        text-align: left;
      }
    }

    &.right {
      .cmcty-select-selector {
        text-align: right;
      }
    }
  }

  .project-number .cmcty-input-number-input {
    text-align: right;
  }

  td.cmcty-table-cell.align-left {
    text-align: left;
  }

  td.cmcty-table-cell.align-right {
    padding-right: 6px;
    text-align: right;
  }

  td.cmcty-table-cell.align-center {
    text-align: center;
  }

  .cmcty-table-cell-scrollbar {
    display: table-cell;
  }

  .cmcty-table-row {
    content-visibility: hidden;
  }

  .ellipsis-text {
    width: 100%;
    text-align: left;

    span {
      display: inline-block;
      width: inherit;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .multi-ellipsis-btn {
    width: 100%;
    text-align: left;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      white-space: break-spaces;
    }
  }

  .multi-ellipsis-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    white-space: break-spaces;
  }

  .cmcty-table-pagination.cmcty-table-pagination-right {
    position: absolute;
    top: unset;
    right: 0;
    bottom: -30px;
    left: unset;
    z-index: 5;
    display: inline-block;
    margin: 4px 10px;
    background-color: #fff;
  }

  .diff .cmcty-select-selection-item {
    color: red;
  }

  .sfdc-number {
    &.greater {
      /* // color: green; */
      color: red;
    }

    &.less {
      color: red;
    }
  }

  .sfdc-select {
    &.greater {
      .cmcty-select-selection-item {
        color: red;
      }
    }

    &.less {
      .cmcty-select-selection-item {
        /* // color: green; */
        color: red;
      }
    }
  }

  .sfdc-date {
    &.greater {
      input {
        /* // color: green; */
        color: red;
      }
    }

    &.less {
      input {
        color: red;
      }
    }
  }

  .table-item-disable {
    &.cmcty-select-disabled.cmcty-select:not(.cmcty-select-customize-input) {
      .cmcty-select-selector {
        color: #333;
        background-color: #fff;
      }
    }

    .cmcty-input-number-input[disabled] {
      color: #333;
      background-color: #fff;
    }

    &.cmcty-picker.cmcty-picker-disabled {
      background-color: #fff;

      input {
        color: #333;
      }
    }
  }

  .cmcty-tabs-tabpane.cmcty-tabs-tabpane-active {
    height: 100%;
  }

  .cmcty-table-checkbox-hidden {
    label {
      display: none;
    }
  }
}

.project-table {
  td {
    padding: 4px;
  }

  .small-column,
  .small-column *,
  .small-column .cmcty-picker-input > input {
    font-size: 14px;
  }
}

.resource-regulation {
  .chart-card .cmcty-card-body {
    padding: 12px;
  }
}

.res-sim-modal {
  .res-sim-table {
    tr.light-selected,
    .cmcty-table-tbody tr.cmcty-table-row-selected.light-selected {
      background-color: rgb(237, 246, 255);
      > td.cmcty-table-cell-fix-left,
      > td.cmcty-table-cell-fix-right,
      > td.cmcty-table-cell-row-hover,
      > td.cmcty-table-cell,
      > td.cmcty-table-column-sort {
        background-color: rgb(255, 255, 255);
      }
    }
  }
}

