.sub-diff-container {
  .operation-container {
    margin-bottom: 6px;
    padding: 4px 10px;
    background-color: white;
  }
  .border-left {
    border-inline-start: 1px solid #838282 !important;
  }
  .border-right-none {
    border-inline-end: none !important;
  }
  .border-buttom-line {
    td:first-child {
      border-bottom-color: #838282 !important;
    }
  }
  .count-row {
    font-weight: bolder;
    background-color: #e5e5e5;

    td {
      border-bottom-color: #838282 !important;
    }

    td:first-child {
      background-color: #e5e5e5;
    }
  }

  .cate-row {
    font-weight: bolder;
    background-color: #e5e5e5;
    .bac {
      background: #e5e5e5 !important;
    }
    td {
      border-bottom-color: #838282 !important;
    }

    td:first-child {
      background-color: #fff;
    }
  }

  .ofs-row {
    td {
      border-bottom-color: #838282 !important;
    }

    td:first-child {
      background-color: #fff;
    }
  }

  .ofs-total-row {
    font-weight: bolder;
    background-color: #e5e5e5;
    td {
      border-bottom-color: #838282 !important;
    }

    td:first-child {
      background-color: #fff;
    }
  }
}
